import React, { useState, useEffect, useCallback } from 'react';
import { getListasPrecios, deleteListaPrecios } from '../../../api/precios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogTitle, DialogContent, TextField, CircularProgress, TablePagination, Box } from '@mui/material';
import { Edit, Delete, Visibility, Add, Update } from '@mui/icons-material';
import CreateListaPrecios from './_Create';
import EditTarifa from './_Edit';
import AddProductos from './_AddProductos';
import ViewPrecio from './_ViewById';
import { ConfirmDelete } from '../../../components/ConfirmDelete';
import { useAuth } from '../../../context/auth';


const Tarifas = () => {
  const [tarifas, setTarifas] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [selectedTarifa, setSelectedTarifa] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { permisos } = useAuth();
  const { Prm_Config_Tarifas_Crear, Prm_Config_Tarifas_Modificar, Prm_Config_Tarifas_Eliminar } = permisos;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [openAddProductos, setOpenAddProductos] = useState(false);


  const handleOpenDeleteConfirm = useCallback((id: number) => {
    setDeleteId(id);
    setOpenConfirmDelete(true);
  }, []);

  const handleCloseDeleteConfirm = useCallback(() => {
    setOpenConfirmDelete(false);
    setDeleteId(null);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (deleteId !== null) {
      await deleteListaPrecios(deleteId);
      fetchTarifas();
      handleCloseDeleteConfirm();
    }
  }, [deleteId, handleCloseDeleteConfirm]);

  useEffect(() => {
    fetchTarifas();
  }, []);

  const fetchTarifas = useCallback(async () => {
    setLoading(true);
    const response = await getListasPrecios();
    if (response.isOk) {
      setTarifas(response.data);
    }
    setLoading(false);
  }, []);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleOpenAddProductos = () => {
    setOpenAddProductos(true);
  };

  const handleCloseAddProductos = () => {
    setOpenAddProductos(false);
    // Aquí puedes recargar las tarifas o realizar otras acciones
  };

  const filteredTarifas = tarifas.filter((tarifa: any) => tarifa.Tf_Descripcion.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div style={{ marginTop: '5px' }}>
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ marginTop: '5px' }}>
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
            <TextField label='Buscar' value={searchTerm} onChange={handleSearchChange} fullWidth size='small' />
            {Prm_Config_Tarifas_Crear === 1 && (
              <IconButton onClick={() => setOpenCreate(true)} title='Nuevo' sx={{ border: 'solid', marginLeft: '5px', borderColor: '#888', borderWidth: '1px', padding: '5px' }}>
                <Add />
              </IconButton>
            )}
            <IconButton onClick={fetchTarifas} title='Actualizar' sx={{ border: 'solid', marginLeft: '5px', borderColor: '#888', borderWidth: '1px', padding: '5px' }}>
              <Update />
            </IconButton>
          </Box>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>ID</TableCell>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell align='center'>Estado</TableCell>
                  <TableCell align='center'>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTarifas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((tarifa: any) => (
                  <TableRow key={tarifa.Tf_Id}>
                    <TableCell align='center'>{tarifa.Tf_Id}</TableCell>
                    <TableCell>{tarifa.Cl_Nombre}</TableCell>
                    <TableCell>{tarifa.Tf_Descripcion}</TableCell>
                    <TableCell align='center'>{tarifa.Tf_Estado}</TableCell>
                    <TableCell align='center'>
                      <IconButton
                        onClick={() => {
                          setSelectedTarifa(tarifa);
                          setOpenView(true);
                        }}>
                        <Visibility />
                      </IconButton>
                      {Prm_Config_Tarifas_Modificar === 1 && (
                        <IconButton
                          onClick={() => {
                            setSelectedTarifa(tarifa);
                            setOpenAddProductos(true);
                          }}>
                          <Add />
                        </IconButton>
                      )}
                      {Prm_Config_Tarifas_Modificar === 1 && (
                        <IconButton
                          onClick={() => {
                            setSelectedTarifa(tarifa);
                            setOpenEdit(true);
                          }}>
                          <Edit />
                        </IconButton>
                      )}
                      {Prm_Config_Tarifas_Eliminar === 1 && (
                        <IconButton onClick={() => handleOpenDeleteConfirm(tarifa.Tf_Id)} title='Eliminar'>
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination component='div' count={filteredTarifas.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />
        </div>
      )}

      <Dialog open={openCreate} onClose={() => setOpenCreate(false)} maxWidth='md' fullWidth>
        <DialogTitle>Crear Listado de Precios</DialogTitle>
        <DialogContent>
          <CreateListaPrecios
            onClose={() => {
              setOpenCreate(false);
              fetchTarifas();
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openAddProductos} onClose={handleCloseAddProductos}  maxWidth='md' fullWidth>
        <DialogTitle>Agregar productos al listado de precios</DialogTitle>
        <DialogContent>
          <AddProductos
           tarifa={selectedTarifa}
            onClose={handleCloseAddProductos}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}  maxWidth='md' fullWidth>
        <DialogTitle>Editar Listado de Precios</DialogTitle>
        <DialogContent>
          <EditTarifa
            tarifa={selectedTarifa}
            onClose={() => {
              setOpenEdit(false);
              fetchTarifas();
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openView} onClose={() => setOpenView(false)} maxWidth='md' fullWidth>
        <DialogTitle>Ver Listado de Precios</DialogTitle>
        <DialogContent>
          <ViewPrecio tarifa={selectedTarifa} onClose={() => setOpenView(false)} />
        </DialogContent>
      </Dialog>

      <ConfirmDelete open={openConfirmDelete} onClose={handleCloseDeleteConfirm} onConfirm={handleConfirmDelete} message='¿Estás seguro de que deseas eliminar esta listado de precios?' />
    </div>
  );
};

export default Tarifas;
