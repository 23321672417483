import React, { useState, useEffect, useCallback } from 'react';
import { Button, TextField, Box, CircularProgress, Autocomplete, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { getProductosEnInventario } from '../../../api/productos';
import { addProductoToTarifa, getDetalleListaPreciosById } from '../../../api/precios';

interface Producto {
  id: number;
  nombre: string;
  unidad: string;
}

interface ProductoTarifa {
  TfD_Tf_Id: number;
  TfD_Pr_Id: number;
  TfD_Precio: number;
  TfD_PrecioManoObra: number;
  TfD_Observaciones: string;
  Pr_Nombre: string;
}

const AddProductos = React.memo(({ tarifa, onClose }: any) => {
  const [productos, setProductos] = useState<Producto[]>([]);
  const [selectedProducto, setSelectedProducto] = useState<Producto | null>(null);
  const [precio, setPrecio] = useState<string>('');
  const [precioManoObra, setPrecioManoObra] = useState<string>('');
  const [observaciones, setObservaciones] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [productosTarifa, setProductosTarifa] = useState<ProductoTarifa[]>([]);

  const fetchProductos = useCallback(async () => {
    try {
      const response = await getProductosEnInventario();
      if (response.isOk && response.data) {
        const mappedProductos = response.data.map((prod: any) => ({
          id: prod.Pr_Id,
          nombre: prod.Pr_Nombre,
          unidad: prod.Un_Nombre,
        }));
        setProductos(mappedProductos);
      } else {
        setProductos([]);
      }
    } catch (error) {
      console.error('Error inesperado al cargar los productos:', error);
    }
  }, []);

  const fetchProductosTarifa = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getDetalleListaPreciosById(tarifa.Tf_Id);
      if (response.isOk && response.data) {
        console.log("response.data",response.data);
        const mappedProductosTarifa = response.data.map((prod: any) => ({
          TfD_Tf_Id: prod.TfD_Tf_Id,
          TfD_Pr_Id: prod.TfD_Pr_Id,
          TfD_Precio: parseFloat(prod.TfD_Precio) || 0,
          TfD_PrecioManoObra: parseFloat(prod.TfD_PrecioManoObra) || 0,
          TfD_Observaciones: prod.TfD_Observaciones,
          Pr_Nombre: prod.Pr_Nombre,
        }));
        setProductosTarifa(mappedProductosTarifa);
      } else {
        console.error('Error al cargar los productos de la tarifa:', response.message);
      }
    } catch (error) {
      console.error('Error inesperado al cargar los productos de la tarifa:', error);
    } finally {
      setLoading(false);
    }
  }, [tarifa.Tf_Id]);

  useEffect(() => {
    fetchProductos();
    fetchProductosTarifa();
  }, [fetchProductos, fetchProductosTarifa]);

  const handleAddProducto = async () => {
    if (!selectedProducto || !precio || !precioManoObra) {
      alert('Debes completar todos los campos antes de agregar el producto.');
      return;
    }

    const newProducto: ProductoTarifa = {
      TfD_Tf_Id: tarifa.Tf_Id,
      TfD_Pr_Id: selectedProducto.id,
      TfD_Precio: parseFloat(precio),
      TfD_PrecioManoObra: parseFloat(precioManoObra),
      TfD_Observaciones: observaciones,
      Pr_Nombre: selectedProducto.nombre + ' ' + selectedProducto.unidad,
    };

    setLoading(true);
    try {
      const response = await addProductoToTarifa(newProducto);
      if (response.isOk) {
        setProductosTarifa((prev) => [...prev, newProducto]);
        setSelectedProducto(null);
        setPrecio('');
        setPrecioManoObra('');
        setObservaciones('');
      } else {
        console.error('Error al agregar el producto:', response.message);
      }
    } catch (error) {
      console.error('Error inesperado al agregar el producto:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <p>
        <strong>Listado de precios:</strong> {tarifa.Tf_Descripcion}
      </p>

      <Autocomplete
        options={productos}
        getOptionLabel={(option) => option.nombre}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedProducto}
        onChange={(event, value) => setSelectedProducto(value)}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.nombre} {option.unidad}
          </li>
        )}
        renderInput={(params) => <TextField {...params} label='Producto' variant='outlined' fullWidth sx={{ marginBottom: '1rem' }} />}
      />

      <TextField label='Precio' value={precio} onChange={(e) => setPrecio(e.target.value)} type='number' fullWidth sx={{ marginBottom: '1rem' }} />

      <TextField label='Precio Mano de Obra' value={precioManoObra} onChange={(e) => setPrecioManoObra(e.target.value)} type='number' fullWidth sx={{ marginBottom: '1rem' }} />

      <TextField label='Observaciones' value={observaciones} onChange={(e) => setObservaciones(e.target.value)} type='string' fullWidth sx={{ marginBottom: '1rem' }} />

      <Box display='flex' justifyContent='space-between' sx={{ marginBottom: '1rem' }}>
        <Button onClick={handleAddProducto} variant='outlined' disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Agregar Producto'}
        </Button>

        <Button onClick={onClose} variant='outlined' color='error'>
          Cancelar
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ marginTop: '1rem' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>ID Producto</TableCell>
              <TableCell>Nombre Producto</TableCell>
              <TableCell>Notas</TableCell>
              <TableCell align='center'>Precio</TableCell>
              <TableCell align='center'>Precio Mano de Obra</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productosTarifa.map((producto, index) => (
              <TableRow key={`${producto.TfD_Tf_Id}-${producto.TfD_Pr_Id}-${index}`}>
                <TableCell align='center'>{producto.TfD_Pr_Id}</TableCell>
                <TableCell>{producto.Pr_Nombre}</TableCell>
                <TableCell>{producto.TfD_Observaciones}</TableCell>
                <TableCell align='center'>{producto.TfD_Precio.toFixed(2)}</TableCell>
                <TableCell align='center'>{producto.TfD_PrecioManoObra.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export default AddProductos;
