// src/pages/Home.tsx
import React from 'react';
import { Paper } from '@mui/material';
import { Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import Usuarios from './Usuarios';
import Productos from './Productos';
import Instaladores from './Instaladores';
import TipoIdentificacion from './TipoIdentificacion';
import Clientes from './Clientes';
import Unidades from './Unidades';
import Precios from './Precios';
import Categorias from './Categorias';
import { useAuth } from '../../context/auth';
import { AccessDenied } from '../../components/AccessDenied/Index';

const Home: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { permisos } = useAuth();
  const { Prm_Config, Prm_Config_Usr_Ver, Prm_Config_Instal_Ver, Prm_Config_Tip_Id_Ver, Prm_Config_Cltes_Ver, Prm_Config_Prod_Ver, Prm_Config_Categ_Ver, Prm_Config_Unid_Ver } = permisos;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  if (Prm_Config === 0) {
    return <AccessDenied />;
  }

  return (
    <Paper elevation={3} sx={{ p: 3, height: 'calc(100% - 90px)', marginBottom: 50, overflow: 'auto' }}>
      <h4 style={{ marginTop: -10 }}>Configuracion</h4>

      <Tabs value={activeTab} onChange={handleTabChange} scrollButtons='auto' variant='scrollable'>
        {Prm_Config_Usr_Ver === 1 && <Tab label='Usuarios' />}
        {Prm_Config_Instal_Ver === 1 && <Tab label='Instaladores' />}
        {Prm_Config_Tip_Id_Ver === 1 && <Tab label='Tipos Identificacion' />}
        {Prm_Config_Cltes_Ver === 1 && <Tab label='Clientes' />}
        {Prm_Config_Prod_Ver === 1 && <Tab label='Productos' />}
        {Prm_Config_Categ_Ver === 1 && <Tab label='Categorias' />}
        {Prm_Config_Unid_Ver === 1 && <Tab label='Unidades' />}
        {Prm_Config_Unid_Ver === 1 && <Tab label='Listas de Precios' />}
      </Tabs>
      {activeTab === 0 && <Usuarios />}
      {activeTab === 1 && <Instaladores />}
      {activeTab === 2 && <TipoIdentificacion />}
      {activeTab === 3 && <Clientes />}
      {activeTab === 4 && <Productos />}
      {activeTab === 5 && <Categorias />}
      {activeTab === 6 && <Unidades />}
      {activeTab === 7 && <Precios />}
    </Paper>
  );
};

export default Home;
