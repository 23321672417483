import React, { useEffect, useState } from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import { getListasPrecios, getDetalleListaPreciosById } from '../../api/precios';

const ListaPreciosModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const [listasPrecios, setListasPrecios] = useState<any[]>([]);
  const [selectedLista, setSelectedLista] = useState<number | null>(null);
  const [detallePrecios, setDetallePrecios] = useState<any[]>([]);
  const [loadingListas, setLoadingListas] = useState<boolean>(true);
  const [loadingDetalle, setLoadingDetalle] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>(''); // Estado para el buscador

  const fetchListasPrecios = async () => {
    setLoadingListas(true);
    try {
      const response = await getListasPrecios();
      if (response.isOk && response.data) {
        setListasPrecios(response.data);
      } else {
        setListasPrecios([]);
      }
    } catch (error) {
      console.error('Error inesperado al cargar las listas de precios:', error);
    } finally {
      setLoadingListas(false);
    }
  };

  useEffect(() => {
    fetchListasPrecios();
  }, []);

  const handleSelectLista = async (listaId: number) => {
    setSelectedLista(listaId);
    setLoadingDetalle(true);
    try {
      const response = await getDetalleListaPreciosById(listaId);
      if (response.isOk && response.data) {
        setDetallePrecios(response.data);
      } else {
        setDetallePrecios([]);
      }
    } catch (error) {
      console.error('Error inesperado al cargar los detalles de la lista de precios:', error);
    } finally {
      setLoadingDetalle(false);
    }
  };

  const filteredDetallePrecios = detallePrecios.filter((detalle: any) => detalle.Pr_Nombre.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge='start' color='inherit' onClick={onClose} aria-label='close'>
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            Listas de Precios
          </Typography>
        </Toolbar>
      </AppBar>
      <Box display='flex' flexDirection='row' height='100%'>
        <Box width='30%' overflow='auto' borderRight={1} borderColor='divider'>
          {loadingListas ? (
            <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableBody>
                  {listasPrecios.map((lista) => (
                    <TableRow key={lista.Tf_Id} hover onClick={() => handleSelectLista(lista.Tf_Id)} selected={selectedLista === lista.Tf_Id}>
                      <TableCell>{lista.Tf_Descripcion}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        <Box width='70%' overflow='auto' padding={2}>
          {loadingDetalle ? (
            <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
              <CircularProgress />
            </Box>
          ) : selectedLista ? (
            <>
              <TextField label='Buscar producto' variant='outlined' fullWidth sx={{ marginBottom: 2 }} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>ID Producto</TableCell>
                      <TableCell>Producto</TableCell>
                      <TableCell>Notas</TableCell>
                      <TableCell align='center'>Precio</TableCell>
                      <TableCell align='center'>Precio Mano de Obra</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredDetallePrecios.map((detalle: any) => (
                      <TableRow key={detalle.TfD_Id}>
                        <TableCell align='center'>{detalle.TfD_Pr_Id}</TableCell>
                        <TableCell>{detalle.Pr_Nombre}</TableCell>
                        <TableCell>{detalle.TfD_Observaciones}</TableCell>
                        <TableCell align='center'>{detalle.TfD_Precio !== null && !isNaN(detalle.TfD_Precio) ? parseFloat(detalle.TfD_Precio).toFixed(2) : 'N/A'}</TableCell>
                        <TableCell align='center'>{detalle.TfD_PrecioManoObra !== null && !isNaN(detalle.TfD_PrecioManoObra) ? parseFloat(detalle.TfD_PrecioManoObra).toFixed(2) : 'N/A'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Typography variant='h6' textAlign='center'>
              Selecciona una lista de precios para ver sus detalles
            </Typography>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ListaPreciosModal;
