import { UseApi } from '../utils/axios-pettitions';

export async function getMaterialesGlobal() {
  try {
    const { runApi } = UseApi();
    const response = await runApi('material/registro/global', 'GET');    
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener los productos',
    };
  }
}

export async function getMaterialesBlogalById(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi(`material/registro/global/${id}`, 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener el producto',
    };
  }
}


export async function getDetalleMaterialGlobal(id: number) {
  try {
    const { runApi } = UseApi();        
    const response = await runApi(`material/registro/globalDetalle`, 'GET',{id});
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener el detalle del producto',
    };
  }
}


export async function updateDetalleMaterialGlobal(id: number, data: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi(`material/registro/globalDetalle`, 'PUT', {id}, data);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar el producto',
    };
  }
}