import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import { useAuth } from '../../context/auth';
import { abonarCredito } from '../../api/creditos';

export const Abonar = React.memo(({ onClose, open, data }: any) => {
  const [loading, setLoading] = React.useState(false);
  const { user } = useAuth();
  const [abono, setAbono] = React.useState({
    IdCliente: '',
    IdUsuario: 1,
    Monto: undefined,
    Nota: '',
  });

  const [errors, setErrors] = React.useState({
    Monto: '',
    Nota: '',
  });

  const validate = () => {
    let tempErrors = { Monto: '', Nota: '' };
    if (!abono.Monto) tempErrors.Monto = 'El Monto es requerido';
    if (!abono.Nota) tempErrors.Nota = 'La Nota es requerida';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAbono((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    const dataSend = {
        IdCliente: data.Cl_Id,
        IdUsuario: user?.Us_Id,
        Monto: abono.Monto,
        Nota: abono.Nota,
      }
    await abonarCredito(dataSend);
    setLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Abonar a créditos</DialogTitle>
      <DialogContent>
        <form>
          <TextField label='Monto' name='Monto' value={abono.Monto} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Monto} helperText={errors.Monto} />
          <TextField label='Notas' name='Nota' value={abono.Nota} onChange={handleChange} fullWidth required sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' error={!!errors.Nota} helperText={errors.Nota} />
          <Box display='flex' justifyContent='space-between'>
            <Button onClick={handleSubmit} variant='outlined' size='small' disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Crear'}
            </Button>
            <Button onClick={onClose} variant='outlined' color='error' size='small'>
              Cancelar
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
});
