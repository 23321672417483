import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, CircularProgress } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { getDetalleListaPreciosById, deleteDetalleListaPrecios, updateDetalleListaPrecios } from '../../../api/precios';
import { useAuth } from '../../../context/auth';
import { ConfirmDelete } from '../../../components/ConfirmDelete';

const ViewPrecio = React.memo(({ tarifa, onClose }: any) => {
  const [productos, setProductos] = useState([]);
  const { permisos } = useAuth();
  const { Prm_Config_Tarifas_Modificar, Prm_Config_Tarifas_Eliminar } = permisos;
  const [selectedTarifa, setSelectedTarifa] = useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [editedValues, setEditedValues] = useState({ idProducto: 0, precio: '', precioManoObra: '', observaciones: '' });

  const fetchProductos = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getDetalleListaPreciosById(tarifa.Tf_Id);
      if (response.isOk && response.data) {
        setProductos(response.data);
      } else {
        setProductos([]);
      }
    } catch (error) {
      console.error('Error inesperado al cargar los productos:', error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchProductos();
  }, []);

  const handleOpenDeleteConfirm = useCallback((id: number) => {
    setDeleteId(id);
    setOpenConfirmDelete(true);
  }, []);

  const handleCloseDeleteConfirm = useCallback(() => {
    setOpenConfirmDelete(false);
    setDeleteId(null);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (deleteId !== null) {
      await deleteDetalleListaPrecios(deleteId);
      fetchProductos();
      handleCloseDeleteConfirm();
    }
  }, [deleteId, handleCloseDeleteConfirm]);

  const handleEditClick = (index: number, producto: any) => {
    setEditingRow(index);
    setEditedValues({
      idProducto: producto.TfD_Pr_Id,
      precio: producto.TfD_Precio.toString(),
      precioManoObra: producto.TfD_PrecioManoObra.toString(),
      observaciones: producto.TfD_Observaciones.toString(),
    });
  };

  const handleSave = async (detalleId: number) => {
    try {
      const updatedProducto = {
        TfD_Pr_Id: editedValues.idProducto,
        TfD_Precio: parseFloat(editedValues.precio),
        TfD_PrecioManoObra: parseFloat(editedValues.precioManoObra),
        TfD_Observaciones: editedValues.observaciones,
      };
      await updateDetalleListaPrecios(detalleId, updatedProducto);
      fetchProductos();
      setEditingRow(null);
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedValues((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div style={{ marginTop: '5px' }}>
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <form>
          <TextField label='Cliente' value={tarifa.Cl_Nombre} fullWidth disabled sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' />
          <TextField label='Descripcion' value={tarifa.Tf_Descripcion} fullWidth disabled sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' />

          <TableContainer component={Paper} sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>ID Producto</TableCell>
                  <TableCell>Nombre Producto</TableCell>
                  <TableCell>Notas</TableCell>
                  <TableCell align='center'>Precio</TableCell>
                  <TableCell align='center'>Precio Mano de Obra</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productos.map((producto: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell align='center'>{producto.TfD_Pr_Id}</TableCell>
                    <TableCell>{producto.Pr_Nombre}</TableCell>
                    <TableCell align="center">
                      {editingRow === index ? (
                        <TextField
                          name="observaciones"
                          value={editedValues.observaciones}
                          onChange={handleChange}
                          onBlur={() => handleSave(producto.TfD_Id)}
                          onKeyDown={(e) => e.key === 'Enter' && handleSave(producto.TfD_Id)}
                          size="small"
                        />
                      ) : (
                        producto.TfD_Observaciones !== null ? producto.TfD_Observaciones : ''
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {editingRow === index ? (
                        <TextField
                          name="precio"
                          value={editedValues.precio}
                          onChange={handleChange}
                          onBlur={() => handleSave(producto.TfD_Id)}
                          onKeyDown={(e) => e.key === 'Enter' && handleSave(producto.TfD_Id)}
                          size="small"
                        />
                      ) : (
                        producto.TfD_Precio !== null && !isNaN(producto.TfD_Precio) ? parseFloat(producto.TfD_Precio).toFixed(2) : 'N/A'
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {editingRow === index ? (
                        <TextField
                          name="precioManoObra"
                          value={editedValues.precioManoObra}
                          onChange={handleChange}
                          onBlur={() => handleSave(producto.TfD_Id)}
                          onKeyDown={(e) => e.key === 'Enter' && handleSave(producto.TfD_Id)}
                          size="small"
                        />
                      ) : (
                        producto.TfD_PrecioManoObra !== null && !isNaN(producto.TfD_PrecioManoObra) ? parseFloat(producto.TfD_PrecioManoObra).toFixed(2) : 'N/A'
                      )}
                    </TableCell>  
                    <TableCell>
                      {Prm_Config_Tarifas_Modificar === 1 && (
                        <IconButton onClick={() => handleEditClick(index, producto)} title='Editar'>
                          <Edit />
                        </IconButton>
                      )}
                      {Prm_Config_Tarifas_Eliminar === 1 && (
                        <IconButton onClick={() => handleOpenDeleteConfirm(producto.TfD_Id)} title='Eliminar'>
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display='flex' justifyContent='flex-end'>
            <Button onClick={onClose} variant='outlined' color='error' size='small'>
              Cerrar
            </Button>
          </Box>
          <ConfirmDelete open={openConfirmDelete} onClose={handleCloseDeleteConfirm} onConfirm={handleConfirmDelete} message='¿Estás seguro de que deseas eliminar este producto del listado?' />
        </form>
      )}
    </div>
  );
});

export default ViewPrecio;
