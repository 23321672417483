import React, { useState, useEffect } from 'react';
import { updateListaPrecios } from '../../../api/precios';
import { getClientes } from '../../../api/clientes';
import { Button, TextField, Box, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

interface Cliente {
  id: number;
  nombre: string;
}

const EditTarifa = React.memo(({ tarifa, onClose }: any) => {
  const [formData, setFormData] = useState(tarifa);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    Tf_Descripcion: '',
    Tf_Cl_Id: '',
  });

  useEffect(() => {
    setFormData(tarifa);
  }, [tarifa]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleClienteChange = (event: any, value: Cliente | null) => {
    setFormData((prev: any) => ({ ...prev, Tf_Cl_Id: value ? value.id : 0 }));
  };

  const validate = () => {
    let tempErrors = {
      Tf_Descripcion: '',
      Tf_Cl_Id: '',
    };
    if (!formData.Tf_Cl_Id) tempErrors.Tf_Cl_Id = 'El cliente es requerido';
    if (!formData.Tf_Descripcion.trim()) tempErrors.Tf_Descripcion = 'La descripción es requerida';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      await updateListaPrecios(formData.Tf_Id, formData);
      onClose();
    } catch (error) {
      console.error('Error al actualizar la tarifa:', error);
    } finally {
      setLoading(false);
    }
  };

    const [clientes, setClientes] = useState<Cliente[]>([]);
  
    useEffect(() => {
      const fetchClientes = async () => {
        try {
          const response = await getClientes();
          if (response.isOk && response.data) {
            const mappedClientes = response.data
              .filter((cliente: any) => cliente.Cl_Id && cliente.Cl_Nombre)
              .map((cliente: any) => ({
                id: cliente.Cl_Id,
                nombre: cliente.Cl_Nombre,
              }));
            setClientes(mappedClientes);
          } else {
            console.error('Error al cargar los clientes:', response.message);
          }
        } catch (error) {
          console.error('Error inesperado al cargar los clientes:', error);
        }
      };
      fetchClientes();
    }, []);

  return (
    <div>
      <form>
        <Autocomplete
          options={clientes}
          getOptionLabel={(option: Cliente) => option.nombre}
          value={clientes.find((c: Cliente) => c.id === formData.Tf_Cl_Id) || null}
          onChange={handleClienteChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Cliente"
              variant="outlined"
              fullWidth
              error={!!errors.Tf_Cl_Id}
              helperText={errors.Tf_Cl_Id}
              sx={{ marginBottom: '1rem', marginTop: '1rem' }}
              size="small"
            />
          )}
        />

        <TextField
          label="Descripción"
          name="Tf_Descripcion"
          value={formData.Tf_Descripcion}
          onChange={handleChange}
          fullWidth
          required
          sx={{ marginBottom: '1rem', marginTop: '1rem' }}
          size="small"
          error={!!errors.Tf_Descripcion}
          helperText={errors.Tf_Descripcion}
        />

        <FormControlLabel
          control={
            <Checkbox
              name="Tf_Estado"
              checked={formData.Tf_Estado}
              onChange={handleChange}
              color="primary"
            />
          }
          label="Activo"
          sx={{ marginBottom: '1rem' }}
        />

        <Box display="flex" justifyContent="space-between">
          <Button onClick={handleSubmit} variant="outlined" size="small" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Actualizar'}
          </Button>
          <Button onClick={onClose} variant="outlined" color="error" size="small">
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default EditTarifa;
