import React, { useState, useEffect } from 'react';
import { createListaPrecios } from '../../../api/precios';
import { getClientes } from '../../../api/clientes';
import { Button, TextField, Box, CircularProgress, FormControlLabel,  SelectChangeEvent, Checkbox, Select } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

interface Cliente {
  id: number;
  nombre: string;
}

interface FormData {
  Tf_Cl_Id: number;
  Tf_Descripcion: string;
  Tf_Estado: boolean;
}

const CreateListaPrecios: React.FC<{ onClose: () => void }> = React.memo(({ onClose }) => {
  const [formData, setFormData] = useState<FormData>({
    Tf_Cl_Id: 0,
    Tf_Descripcion: '',
    Tf_Estado: true, // Estado por defecto como activo
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    Tf_Cl_Id: '',
    Tf_Descripcion: '',
    Tf_Estado: '',
  });

  const [clientes, setClientes] = useState<Cliente[]>([]);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await getClientes();
        if (response.isOk && response.data) {
          const mappedClientes = response.data
            .filter((cliente: any) => cliente.Cl_Id && cliente.Cl_Nombre)
            .map((cliente: any) => ({
              id: cliente.Cl_Id,
              nombre: cliente.Cl_Nombre,
            }));
          setClientes(mappedClientes);
        } else {
          console.error('Error al cargar los clientes:', response.message);
        }
      } catch (error) {
        console.error('Error inesperado al cargar los clientes:', error);
      }
    };
    fetchClientes();
  }, []);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleClienteChange = (event: any, value: Cliente | null) => {
    setFormData((prev) => ({ ...prev, Tf_Cl_Id: value ? value.id : 0 }));
  };

  const validate = () => {
    let tempErrors = {
      Tf_Cl_Id: '',
      Tf_Descripcion: '',
      Tf_Estado: '',
    };
    if (formData.Tf_Cl_Id === 0) tempErrors.Tf_Cl_Id = 'El cliente es requerido';
    if (!formData.Tf_Descripcion.trim()) tempErrors.Tf_Descripcion = 'La descripción es requerida';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      await createListaPrecios(formData);
      onClose();
    } catch (error) {
      console.error('Error al crear la lista de precios:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form>
        <Autocomplete
          options={clientes}
          getOptionLabel={(option) => option.nombre}
          onChange={handleClienteChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Cliente"
              variant="outlined"
              fullWidth
              error={!!errors.Tf_Cl_Id}
              helperText={errors.Tf_Cl_Id}
              sx={{ marginBottom: '1rem', marginTop: '1rem' }}
              size="small"
            />
          )}
        />

        <TextField
          label="Descripción"
          name="Tf_Descripcion"
          value={formData.Tf_Descripcion}
          onChange={(e) => setFormData({ ...formData, Tf_Descripcion: e.target.value })}
          fullWidth
          required
          sx={{ marginBottom: '1rem', marginTop: '1rem' }}
          size="small"
          error={!!errors.Tf_Descripcion}
          helperText={errors.Tf_Descripcion}
        />

        <FormControlLabel
          control={
            <Checkbox
              name="Tf_Estado"
              checked={formData.Tf_Estado}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label="Activo"
          sx={{ marginBottom: '1rem' }}
        />

        <Box display="flex" justifyContent="space-between">
          <Button onClick={handleSubmit} variant="outlined" size="small" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Crear'}
          </Button>
          <Button onClick={onClose} variant="outlined" color="error" size="small">
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default CreateListaPrecios;
