import { UseApi } from '../utils/axios-pettitions';

export async function getListasPrecios() {
  try {
    const { runApi } = UseApi();
    const response = await runApi('tarifas', 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener las unidades',
    };
  }
}

export async function getListaPrecioById(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('tarifas', 'GET', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener la lista de precios',
    };
  }
}

export async function createListaPrecios(Tarifa: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('tarifas', 'POST', null, Tarifa);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al crear el lista de precios',
    };
  }
}

export async function updateListaPrecios(id: number, tarifa: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('tarifas', 'PUT', { id }, tarifa);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar la unidad',
    };
  }
}

export async function deleteListaPrecios(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('tarifas', 'DELETE', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al eliminar la lista de precios',
    };
  }
}

export async function addProductoToTarifa(TarifaDetalle: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('tarifas/detalle', 'POST', null, TarifaDetalle);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al crear el lista de precios',
    };
  }
}


export async function getDetalleListaPreciosById(tarifaId: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('tarifas/detalle', 'GET', { tarifaId });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener la lista de precios',
    };
  }
}

export async function updateDetalleListaPrecios(detalleId: number, tarifa: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('tarifas/detalle', 'PUT', { detalleId }, tarifa);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar el listadp',
    };
  }
}

export async function deleteDetalleListaPrecios(detalleId: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('tarifas/detalle', 'DELETE', { detalleId });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al eliminar el producto de la lista de precios',
    };
  }
}